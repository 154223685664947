<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-form ref="form" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="6" v-if="isAdmin">
            <el-form-item label="运营商" prop="topic">
              <el-select
                v-model="queryInfo.condition.operatorName"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in userOperatorList"
                  :key="item.id"
                  :label="item.operatorName"
                  :value="item.operatorName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="分红日期" prop="topic">
              <el-date-picker
                v-model="monthTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12" align="right">
            <el-button type="primary" plain @click="reset">重置</el-button>
            <el-button type="primary" @click="search">搜索</el-button>
            <el-button type="primary" plain @click="toExport">导出</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="box1">
        <el-tag size="medium">分红总额：{{ dividendStatistics }}</el-tag>
        <el-tag size="medium">月分红：{{ monthIncome }}</el-tag>
        <el-tag size="medium">日分红：{{ todayIncome }}</el-tag>
      </div>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50" align="center">
        </el-table-column>
        <!-- <el-table-column label="省市区" align="center"  prop="memberName" >
                        <template slot-scope="scope">
                            <span>{{ scope.row.region }}</span>
                        </template>
                    </el-table-column> -->
        <el-table-column label="订单号" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="电子围栏名称" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.electronicFenceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" align="center" prop="id" v-if="false">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="运营商名称" align="center" prop="memberCode">
          <template slot-scope="scope">
            <span>{{ scope.row.operatorName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分红金额" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.operatorFee || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == 0">未完成</span>
            <span v-if="scope.row.orderStatus == 1">未支付</span>
            <span v-else>已支付</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="createDt">
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>

        <!-- <el-table-column
                        label="操作"
                        align="center"
                        class-name="small-padding fixed-width"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                                class="item"
                                effect="dark"
                                content="查看详情"
                                placement="bottom"
                        >
                            <el-button type="primary" icon="el-icon-info" circle
                                    @click="gotoDetail(scope.row.id, scope.row.userCode)"
                         ></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column> -->
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 306, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      searchInput: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          operatorName: "",
          operatorCode: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      monthTime: [],
      dividendStatistics: 0,
      monthIncome: 0, //月分红
      todayIncome: 0, //日分红
      userOperatorList: [],
      isAdmin: false
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    this.getListData();
    this.getUserOperatorlist();
    this.getDividendStatistics();
    this.getMonthlyDividends();
    window.addEventListener("resize", this.getHeight);

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo.loginName == "admin") {
      this.isAdmin = true;
    }
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    getUserOperatorlist() {
      this.$http.post("/userOperator/queryList", {}).then(res => {
        if (res.data.code == 200) {
          this.userOperatorList = res.data.data;
        }
      });
    },
    //导出
    toExport() {
      this.$http
        .post("/memberOrder/downExcel", this.queryInfo.condition)
        .then(res => {
          if (res.data.code == 200) {
            window.open(res.data.data);
          }
        });
    },
    //条件查询
    search() {
      // this.queryInfo.condition.operatorName = this.searchInput;
      this.getListData();
      this.getDividendStatistics();
      this.getMonthlyDividends();
    },
    //重置
    reset() {
      this.queryInfo.condition = {};
      this.monthTime = [];
      this.getListData();
      this.getDividendStatistics();
      this.getMonthlyDividends();
    },

    //日月分红
    getMonthlyDividends() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.queryInfo.condition.operatorCode = userInfo.operatorCode;
      this.queryInfo.condition.queryStartDate = this.monthTime[0];
      this.queryInfo.condition.queryEndDate = this.monthTime[1];
      this.queryInfo.condition.userCode = userInfo.userCode;
      this.$http
        .post("/memberOrder/queryIncome", {
          condition: this.queryInfo.condition
        })
        .then(res => {
          if (res.data.code === 200) {
            this.monthIncome = res.data.data.monthIncome;
            this.todayIncome = res.data.data.todayIncome;
          }
        });
    },
    judgeStatus(orderStatus) {
      if (orderStatus === 0) {
        return "未完成";
      } else if (orderStatus === 1) {
        return "未支付";
      } else if (orderStatus === 2) {
        return "已完成";
      } else {
        return "";
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
      this.getDividendStatistics();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
      this.getDividendStatistics();
    },

    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/financeMag/details",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },
    getListData() {
      // 获取列表
      var that = this;
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.queryInfo.condition.operatorCode = userInfo.operatorCode;
      this.queryInfo.condition.queryStartDate = this.monthTime[0];
      this.queryInfo.condition.queryEndDate = this.monthTime[1];
      this.queryInfo.condition.userCode = userInfo.userCode;
      that.$http
        .post("/memberOrder/listOperator", that.queryInfo)
        .then(function(response) {
          //   console.log("------")
          // console.log(response);
          if (response.data.code == 200) {
            // console.log("------")
            // console.log(response.data.data.data)
            that.total = response.data.data.count;
            //   that.queryInfo.page=response.data.page
            that.tableData = response.data.data.data;
          }
        });
    },
    //获取分红统计
    getDividendStatistics() {
      this.queryInfo.condition.queryStartDate = this.monthTime[0];
      this.queryInfo.condition.queryEndDate = this.monthTime[1];
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.queryInfo.condition.operatorCode = userInfo.operatorCode;
      this.queryInfo.condition.userCode = userInfo.userCode;
      // var data = this.queryInfo.condition;
      // data.condition = {};
      // data.condition.userCode = JSON.parse(
      //   localStorage.getItem("userInfo")
      // ).userCode;
      this.$http
        .post("/memberOrder/statisticsBonus", {
          condition: this.queryInfo.condition
        })
        .then(res => {
          if (res.data.code === 200) {
            this.dividendStatistics = res.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 306;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;

    .el-select > .el-input {
      width: 200px;
    }

    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
.box1 {
  display: flex;
  gap: 20px;
}
</style>
